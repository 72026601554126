.user-info {
}
.user-info__avatar {
	position: relative;

	width: 64px;
	height: 64px;
}
.user-info__avatar-edit {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 2;	
}
.user-info__avatar-edit:hover {
	
}